import { Avatar, Tabs, Typography } from "antd";
import { useService } from "../store/hooks";
import { IDatabaseService } from "../types";
import { WordEdit } from "../views/word-edit";
import styles from "./index.module.scss";
import { UserOutlined } from "@ant-design/icons";
import { Copilot } from "../views/copilot";
import { WordCard } from "../views/word-card";
const { Text, Link, Title } = Typography;

export const App: React.FC = () => {
  const databaseService = useService(IDatabaseService);
  return (
    <div className={styles.root}>
      {/* <div className={styles.header}>
        <Text style={{ textAlign: "center" }} ellipsis>
          每一个单词都是通向世界的一块砖石，每天进步一点点!
        </Text>
        <Avatar
          src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
          icon={<UserOutlined />}
          className={styles.headeravatar}
        />
      </div> */}
      <div className={styles.body}>
        {/* <div className={styles.preview}>preview</div> */}
        <div className={styles.edit} style={{ display: "none" }}>
          {/* <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "word",
                label: "word",
                children: (
                  <div className={styles.editcontainer}>
                    <WordEdit />
                  </div>
                ),
              },
              {
                key: "group",
                label: "group",
                children: "phrasephrase",
              },
            ]}
            onChange={() => {}}
          /> */}
          <WordCard></WordCard>
        </div>
        <div className={styles.copilit}>
          <Copilot></Copilot>
        </div>
      </div>
    </div>
  );
};
