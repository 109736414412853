/* eslint-disable max-len */
import { ISystemContent } from '../types';

export const checkarticle: ISystemContent =
  () => `I am studying English. Please check the following English short essay for any grammatical or spelling errors and correct them.
  The results can be output in Markdown format. The markdown includes two parts: the revised short essay and the modifications made.
  Please note:
  - Please carefully check the short essay.
  - Do not replace the bolded words, if there are errors in the bolded words, please correct them.
  - The parts in bold from the original text should still remain bold after corrections.

  Here is the original English short essay:`;
