/* eslint-disable max-len */
import { ISystemContent } from '../types';

export const ocrformatting: ISystemContent =
  () => `以下内容是ocr识别的内容，请整理一下，整理成markdown输出。
  注意：请不要添加内容！请不要添加example这样的东西。
  输出样例：
  - except
  The office is open every day except Sundays.

  - receive 1
  All the children will receive a small gift.

  - receipt
  Keep your receipt in case you want to bring it back.

        `;
