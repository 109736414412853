import { type DatabaseService } from "../../services/database-service";

export interface Word {
  id?: number;
  wordName: string;
  wordEnglishTranslation: string;
  wordExampleSentences: string[];
  wordPhrases: number[];
  wordRepresentativePhrase: number;
  wordUserExamples: string[];
  wordUserNotes: string;
  wordRelatedLinks: number[];

  phraseName: string;
  phraseExampleSentences: string[];
  phraseUserSentences: string[];
  phraseRelatedLinks: number[];
  phraseUserNotes: string;

  groupName: string;
  groupWords: number[];
  groupAIGeneratedEssays: string[];
  groupUserNotes: string;
}

export interface IEditWord {
  wordName: string;
  wordEnglishTranslation: string;
  wordUserNotes: string;
}

export type IDatabaseService = DatabaseService;

export const IDatabaseService = Symbol.for("IDatabaseService");
