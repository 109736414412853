import { Avatar, Button, Input, Typography } from "antd";
import ReactMarkdown from "react-markdown";
import {
  AIFlowEngine,
  checkarticle,
  createWordSentences,
  makearticle,
  makeshortArticle,
  ocrformatting,
  createWordSentencesNoEmphasize,
  createWordSentencesByMy,
} from "ai-flow-engine";

import styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";

const { Paragraph, Text, Title } = Typography;

export const Copilot: React.FC = () => {
  const [prompt, setPrompt] = useState("");
  const [chatList, setChatList] = useState<string[]>([]);

  const containerRef = useRef<any>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [chatList]); // 每当

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text strong>AI Tools</Text>
      </div>

      <div className={styles.body} ref={containerRef}>
        {chatList.map((item) => {
          return (
            <div className={styles.chat}>
              <Avatar
                className={styles.copycontainer}
                icon={
                  <Paragraph
                    className={styles.copyicon}
                    copyable={{ text: item }}
                  ></Paragraph>
                }
              />
              <ReactMarkdown className={styles.markdown}>{item}</ReactMarkdown>
            </div>
          );
        })}
      </div>

      <div className={styles.footer}>
        <Input.TextArea
          value={prompt}
          onChange={(e) => {
            setPrompt(e.target.value);
          }}
        />
        <div className={styles.buttons}>
          {[
            {
              text: "[我的造句] 造句纠正",
              skills: [createWordSentencesByMy],
            },
            {
              text: "[造句]单词",
              skills: [createWordSentences],
            },
            {
              text: "[造句]单词不加粗",
              skills: [createWordSentencesNoEmphasize],
            },
            {
              text: "[写短文]单词极简",
              skills: [makeshortArticle, checkarticle],
            },
            // ---
            {
              text: "[写短文]OCR&句子",
              skills: [ocrformatting, makearticle, checkarticle],
            },
            {
              text: "[写短文]单词&例句",
              skills: [makearticle, checkarticle],
            },
          ].map((item) => {
            return (
              <Button
                key={item.text}
                onClick={() => {
                  const _prompt = prompt;
                  setPrompt("");
                  new AIFlowEngine(item.skills).run(_prompt, (out) => {
                    setChatList(out);
                  });
                }}
              >
                {item.text}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
