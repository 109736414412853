import { IEngine, ISystemContent } from "../types";
import { queryOne } from "../utils/util";
// import event from 'events';

export class AIFlowEngine implements IEngine {
  systemContents: ISystemContent[] = [];
  state: string[] = [];
  constructor(systemContents: ISystemContent[]) {
    this.systemContents = systemContents;
  }
  async run(
    userInput: string,
    callback?: (state: string[]) => void
  ): Promise<string> {
    let query = userInput;
    this.state.push(query);
    callback?.([...this.state]);

    for (const systemContent of this.systemContents) {
      query = await queryOne(systemContent(), query);
      this.state.push(query);
      callback?.([...this.state]);
    }
    this.state = [];
    return query;
  }
}
