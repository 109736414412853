import { injectable } from "inversify";
import { WordDatabase } from "./WordDatabase";
import { Word } from "../../types";

@injectable()
export class DatabaseService {
  private db = new WordDatabase();

  async addWord(word: Word): Promise<number> {
    return await this.db.words.add(word);
  }

  async getWord(id: number): Promise<Word | undefined> {
    return await this.db.words.get(id);
  }

  async updateWord(id: number, word: Partial<Word>): Promise<number> {
    return await this.db.words.update(id, word);
  }

  async deleteWord(id: number): Promise<void> {
    await this.db.words.delete(id);
  }

  async getAllWords(): Promise<Word[]> {
    return await this.db.words.toArray();
  }
}
