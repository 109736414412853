import { openai } from './openai'; // gpt-4-turbo
import dayjs from 'dayjs';
export const modelName = 'gpt-4o';
export async function queryOne(systemContent: string, useContent: string) {
  const res1 = await openai.chat.completions.create({
    model: modelName,
    messages: [
      {
        role: 'system',
        content: `You are ChatGPT, a large language model trained by OpenAI.
  Current model: gpt-4-turbo
  Current time: ${dayjs().format('YYYY/M/D HH:mm:ss')}
  Latex inline: \(x^2\)
  Latex block: $$e=mc^2$$
    `,
      },
      {
        role: 'system',
        content: systemContent,
      },
      {
        role: 'user',
        content: useContent,
      },
    ],
  });
  return res1.choices?.[0]?.message?.content || '';
}
