import { injectable } from "inversify";

import { createStore } from "../../store";
import {
  type IStoreService,
  type ICreateStoreState,
  type ICreateStoreActions,
  type IStoreType,
  type ISlices,
} from "../../types";

@injectable()
class StoreService implements IStoreService {
  private _stores: {
    store: IStoreType;
    slices: ISlices;
  };
  constructor() {
    const res = createStore();
    this._stores = res;
  }
  getSlices(): ISlices {
    return this._stores.slices;
  }

  getState(): ICreateStoreState {
    return this._stores.store.getState();
  }
  getStore(): IStoreType {
    return this._stores.store;
  }
  getActions(): ICreateStoreActions {
    const { slices } = this._stores;
    type ISlicesKeys = keyof typeof slices;

    const dispatchActionsMap: Record<string, Record<string, unknown>> = {};
    (Object.keys(slices) as ISlicesKeys[]).forEach((name) => {
      const { actions } = slices[name];
      type IActions = typeof actions;
      const dispatchActions: Record<string, unknown> = {};
      (Object.keys(actions) as (keyof IActions)[]).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const action = actions[key] as any;
        dispatchActions[key] = (...args: unknown[]) =>
          this._stores.store.dispatch(action(...args));
      });
      dispatchActionsMap[name] = dispatchActions;
    });

    return dispatchActionsMap as ICreateStoreActions;
  }
}

export { StoreService };
