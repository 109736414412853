import Dexie from "dexie";
import { Word } from "../../types";

export class WordDatabase extends Dexie {
  words: Dexie.Table<Word, number>;

  constructor() {
    super("WordDatabase");
    this.version(1).stores({
      words: "++id, wordName, phraseName, groupName",
    });
    this.words = this.table("words");
  }
}
