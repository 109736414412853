import "reflect-metadata";

import { useMemo } from "react";
import { Provider } from "react-redux";
import { createServices } from "../services/create-services";
import { ServicesContext } from "../store/hooks";
import { IStoreService } from "../types";
import { App } from "./app";
import "./index.css";

export const Index: React.FC = () => {
  const services = useMemo(() => {
    return createServices();
  }, []);

  return (
    <ServicesContext.Provider value={services}>
      <Provider store={services.get<IStoreService>(IStoreService).getStore()}>
        <App />
      </Provider>
    </ServicesContext.Provider>
  );
};
