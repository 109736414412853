import { Container } from "inversify";

import { StoreService } from "./store-service";
import { IDatabaseService, IStoreService } from "../types";
import { DatabaseService } from "./database-service";
import { WordDatabase } from "./database-service/WordDatabase";

export function createServices(): Container {
  const container = new Container({
    defaultScope: "Singleton",
  });

  container.bind(IStoreService).to(StoreService);
  container.bind(IDatabaseService).to(DatabaseService);

  return container;
}
