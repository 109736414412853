import {
  type ISlices,
  type ICreateStoreActions,
  type ICreateStoreState,
  type IStoreType,
} from "..";

export interface IStoreService {
  getSlices(): ISlices;
  getStore(): IStoreType;
  getActions(): ICreateStoreActions;
  getState(): ICreateStoreState;
}

export const IStoreService = Symbol.for("IStoreService");
