import { createContext, useContext } from "react";

import { type Container } from "inversify";
import { IAllServicesMap } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ServicesContext = createContext<Container>({} as any);

export function useService<K extends keyof IAllServicesMap>(
  name: K
): IAllServicesMap[K] {
  const container = useContext(ServicesContext);
  return container.get(name);
}
