import {
  createSlice,
  configureStore,
  type PayloadAction,
} from "@reduxjs/toolkit";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type produce } from "immer";

export const createStore = () => {
  const app = createSlice({
    name: "app",
    initialState: {
      app: {},
    },
    reducers: {
      init(state, action: PayloadAction<unknown>): void {},
    },
  });

  const store = configureStore({
    reducer: {
      [app.name]: app.reducer,
    },
  });

  return { store, slices: { app } };
};
